import React, { Suspense, lazy, useCallback, useEffect, useState } from "react";
import api from "../../api/apiContact";
import {
  ContactsPageData,
  FormData,
  LatamPopup as LatamPopupType,
} from "../../types/types";
import PopUp from "components/PopUp/PopUp";
const SupportTeam = lazy(
  () => import("../../components/SupportTeam/SupportTeam")
);
const Connection = lazy(() => import("../../components/Connection/Connection"));
const Modal = lazy(() => import("../../components/Modal/Modal"));
const Button = lazy(() => import("../../components/Button/Button"));
const Address = lazy(() => import("../../components/Address/Address"));

const Contacts: React.FC = () => {
  const [isModal, setIsModal] = useState(false);
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [pageData, setPageData] = useState<ContactsPageData>();
  const [popupData, setPopupData] = useState<LatamPopupType>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.getPopupData();
        setPopupData(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
    setIsModal(false);
  }, []);

  const handleSubmit = useCallback((formData: FormData) => {
    console.log(formData);
    setIsOpen(false);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.getContactsPageData();
        setPageData(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <>
        {pageData && (
          <>
            {pageData.intro && (
              <Connection data={pageData.intro} openModal={openModal} />
            )}
            {/* {pageData.address && <Address data={pageData.address} />} */}

            {isRequestSent ? (
              <Modal
                isOpen={isRequestSent}
                onClose={() => setIsRequestSent(false)}
                className="thank-popup popup"
                title="Thank you!"
              >
                <p className="popup__text text-large">
                  Your request has been successfully sent.
                </p>
                <p className="popup__text text-large">
                  Our expert will contact you soon.
                </p>
                <Button
                  className="popup__button"
                  type="button"
                  onClick={() => setIsRequestSent(false)}
                >
                  Close
                </Button>
              </Modal>
            ) : (
              <Modal
                isOpen={isModal}
                onClose={closeModal}
                className="popup--support popup"
                titleColor="Contact "
                title="our support team"
              >
                <p className="popup__text text-large">
                  Our team is available online to assist you with any questions
                  or concerns you may have.
                </p>
                <SupportTeam className="team" socials={pageData.socials} />
              </Modal>
            )}

            {isRequestSent ? (
              <Modal
                isOpen={isRequestSent}
                onClose={() => setIsRequestSent(false)}
                className="thank-popup popup"
                title="Thank you!"
              >
                <p className="popup__text text-large">
                  Your request has been successfully sent.
                </p>
                <p className="popup__text text-large">
                  Our expert will contact you soon.
                </p>
                <Button
                  className="popup__button"
                  type="button"
                  onClick={() => setIsRequestSent(false)}
                >
                  Close
                </Button>
              </Modal>
            ) : (
              <Modal
                isOpen={isOpen}
                onClose={closeModal}
                className="popup"
                title={(popupData as any)?.title}
              >
                <PopUp
                  className="form-popup"
                  data={popupData}
                  onSubmit={handleSubmit}
                  setIsOpen={setIsOpen}
                  setIsRequestSent={setIsRequestSent}
                />
              </Modal>
            )}
          </>
        )}
      </>
    </Suspense>
  );
};

export default Contacts;
