import React, { Suspense, lazy, useCallback, useEffect, useState } from "react";
import api from "../../api/apiContact";
import {
  AboutPageData,
  FormData,
  LatamPopup as LatamPopupType,
} from "../../types/types";
import PopUp from "components/PopUp/PopUp";

const Benefits = lazy(() => import("../../components/Benefits/Benefits"));
const Payments = lazy(() => import("../../components/Payments/Payments"));
const Modal = lazy(() => import("../../components/Modal/Modal"));
const Button = lazy(() => import("../../components/Button/Button"));

const About: React.FC = () => {
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [pageData, setPageData] = useState<AboutPageData>();
  const [popupData, setPopupData] = useState<LatamPopupType>();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.getPopupData();
        setPopupData(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);
  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleSubmit = useCallback((formData: FormData) => {
    console.log(formData);
    setIsOpen(false);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const res = await api.getAboutPageData();
      setPageData(res.data);
    };

    fetchData();
  }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      {pageData && (
        <>
          {pageData.payments && (
            <Payments data={pageData.payments} openModal={openModal} />
          )}
          {pageData.advantages && (
            <Benefits data={pageData.advantages} openModal={openModal} />
          )}
        </>
      )}
      <>
        {isRequestSent ? (
          <Modal
            isOpen={isRequestSent}
            onClose={() => setIsRequestSent(false)}
            className="thank-popup popup"
            title="Thank you!"
          >
            <p className="popup__text text-large ">
              Your request has been successfully sent.
            </p>
            <p className="popup__text text-large ">
              Our expert will contact you soon.
            </p>
            <Button
              className="popup__button"
              type="button"
              onClick={() => setIsRequestSent(false)}
            >
              Close
            </Button>
          </Modal>
        ) : (
          <Modal
            isOpen={isOpen}
            onClose={closeModal}
            className="popup"
            title={(popupData as any)?.title}
          >
            <PopUp
              className="form-popup"
              data={popupData}
              onSubmit={handleSubmit}
              setIsOpen={setIsOpen}
              setIsRequestSent={setIsRequestSent}
            />
          </Modal>
        )}
      </>
    </Suspense>
  );
};

export default About;
