//@ts-nocheck
import { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import { Outlet } from "react-router-dom";
import Header from "../../components/Header/Header";
import api from "../../api/apiContact";

export const Layout = () => {
  const [check, setCheck] = useState(false);

  useEffect(() => {
    api.checkLang().then((res) => {
      setCheck(res);
    });
  }, []);

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=6LcLzzInAAAAAAafjmJZ_5m7AyC8LAPdp2fEDjd2`,
      function () {
        console.log("Script loaded!");
      }
    );
  }, []);

  return (
    <div className="wrapper" key={check}>
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};
