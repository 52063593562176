import React, { Suspense, useEffect, useState } from "react";
import api from "../../api/apiContact";
import {
  FormData,
  LatamPopup as LatamPopupType,
  MainPageData,
} from "../../types/types";

import Benefits from "../../components/Benefits/Benefits";
import Button from "../../components/Button/Button";
import Connect from "../../components/Connect/Connect";
import CookiePopup from "../../components/CookiePopup/CookiePopup";
import Features from "../../components/Features/Features";
import Gaming from "../../components/Gaming/Gaming";
import ListConect from "../../components/ListConect/ListConect";
import Modal from "../../components/Modal/Modal";
import Options from "../../components/Options/Options";
// import Partners from "../../components/Partners/Partners";
import Solutions from "../../components/Solutions/Solutions";
import Started from "../../components/Started/Started";
import PopUp from "components/PopUp/PopUp";

const Main: React.FC = () => {
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [pageData, setPageData] = useState<MainPageData>();

  const [popupData, setPopupData] = useState<LatamPopupType>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.getPopupData();
        setPopupData(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);
  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleSubmit = (formData: FormData) => {
    setIsOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await api.getMainPageData();
        // console.log(data);

        setPageData(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      {pageData && (
        <>
          {pageData.connect && (
            <>
              <Connect data={pageData.connect} openModal={openModal} />
              <ListConect cards={pageData.connect.cards} />
            </>
          )}
          {pageData.options && <Options data={pageData.options} />}
          {pageData.solutions && (
            <Solutions data={pageData.solutions} openModal={openModal} />
          )}
          {pageData.advantages && (
            <Benefits data={pageData.advantages} openModal={openModal} />
          )}
          {pageData.features && <Features data={pageData.features} />}
          {pageData.empowering && (
            <Gaming data={pageData.empowering} openModal={openModal} />
          )}
          {pageData.getStarted && (
            <Started data={pageData.getStarted} openModal={openModal} />
          )}

          {/* {pageData.partners && <Partners data={pageData.partners} />} */}

          {isRequestSent ? (
            <Modal
              isOpen={isRequestSent}
              onClose={() => setIsRequestSent(false)}
              className="thank-popup popup"
              title="Thank you!"
            >
              <p className="popup__text text-large ">
                Your request has been successfully sent.
              </p>
              <p className="popup__text text-large ">
                Our expert will contact you soon.
              </p>
              <Button
                className="popup__button"
                type="button"
                onClick={() => setIsRequestSent(false)}
              >
                Close
              </Button>
            </Modal>
          ) : (
            <Modal
              isOpen={isOpen}
              onClose={closeModal}
              className="popup"
              title={(popupData as any)?.title}
            >
              <PopUp
                className="form-popup"
                data={popupData}
                onSubmit={handleSubmit}
                setIsOpen={setIsOpen}
                setIsRequestSent={setIsRequestSent}
              />
            </Modal>
          )}
          <CookiePopup className="cookie" />
        </>
      )}
    </Suspense>
  );
};

export default Main;
