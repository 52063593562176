import { ChoiceProps } from "../../../types/types";

const Choice: React.FC<ChoiceProps> = ({
  className,
  choices,
  handleChoiceSelect,
}) => {
  return (
    <div className={className}>
      {choices.map((item) => (
        <label key={item.id} className={`${className}__label`}>
          <input
            type="radio"
            name="connection"
            value={item.title}
            onChange={() => handleChoiceSelect(item)}
          />
          <span className={`${className}__icon`}>
            <img
              width={`${item.image.width}px`}
              height={`${item.image.height}px`}
              src={`${item.image.url}`}
              className="choice__img"
              alt={item.title}
            />
          </span>
          <span className={`${className}__text text-micro`}>{item.title}</span>
        </label>
      ))}
    </div>
  );
};

export default Choice;
