import {
  PopUpProps,
  FormData,
  SharePopupData,
  ChoiceData,
} from "../../types/types";
import api from "../../api/apiContact";
import Button from "../../components/Button/Button";
import React, { useState, useEffect } from "react";
import Choice from "./Choice/Choice";
import DOMPurify from "dompurify";
import TelInput from "../../components/TelInput/TelInput";

interface FormErrors {
  [key: string]: string;
}

const PopUp: React.FC<PopUpProps> = ({
  className,
  onSubmit,
  setIsOpen,
  setIsRequestSent,
  locale,
}) => {
  const [formData, setFormData] = useState<FormData>({
    nameUser: "",
    connection: "",
    connectionItem: "",
    message: "",
    privacyPolicy: true,
  });

  const [errors, setErrors] = useState<FormErrors>({});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [choiceSelected, setChoiceSelected] = useState<ChoiceData>();

  const handleChoiceSelect = (item: any) => {
    setChoiceSelected(item);
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsFormSubmitted(true);

    const sanitizedFormData = {
      ...formData,
      emailTemplate: "popup",
      connectionItem: DOMPurify.sanitize(formData.connectionItem),
      message: DOMPurify.sanitize(formData.message),
      nameUser: DOMPurify.sanitize(formData.nameUser),
      locale,
    };

    const isValid = validateFormData(sanitizedFormData);

    console.log(isValid, errors);

    if (!isValid) return;

    const { grecaptcha } = window as any;

    grecaptcha.ready(async () => {
      try {
        const token = await grecaptcha.execute(
          "6LcLzzInAAAAAAafjmJZ_5m7AyC8LAPdp2fEDjd2",
          {
            action: "submit",
          }
        );

        onSubmit(sanitizedFormData);
        setIsOpen(false);
        setIsRequestSent(true);

        const response = await api.sendPopup(token, sanitizedFormData);

        if (response) {
          console.log("Email sent successfully!");
        } else {
          console.log("Message sending failed");
        }
      } catch (error) {
        console.log("Request failed", error);
      }
    });
  };
  const validateFormData = (formData: FormData) => {
    const FIELDS_TO_CHECK_LENGTH = ["nameUser", "message", "connectionItem"];

    const entriesData = Object.entries(formData);
    const errors: FormErrors = {};

    for (const [key, val] of entriesData) {
      if (FIELDS_TO_CHECK_LENGTH.includes(key)) {
        if (checkForEmptyString(val as string)) {
          errors[key] = "should not be empty";
        }
      }
    }

    if (!errors.connectionItem && choiceSelected) {
      const isValidConnectionItem = validateConnectionItem(
        formData.connectionItem,
        choiceSelected.title
      );

      if (!isValidConnectionItem) {
        errors.connectionItem = "invalid input";
      }
    }

    const isValidPrivacyPolicy = formData.privacyPolicy;

    if (!isValidPrivacyPolicy) {
      errors.privacyPolicy = "must be true";
    }

    setErrors(() => errors);

    return !Object.keys(errors).length;
  };

  const checkForEmptyString = (str: string) => {
    return !str.trim().length;
  };

  const validateConnectionItem = (input: string, placeholder: string) => {
    if (placeholder === "Phone" || placeholder === "WhatsApp") {
      const phoneNumberRegex = /^\+?[0-9]+$/;
      return phoneNumberRegex.test(input);
    }

    if (placeholder === "Telegram") {
      const telegramRegex = /^@[^\s@]+$/;
      return telegramRegex.test(input);
    }

    if (placeholder === "E-mail" || placeholder === "Email") {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return emailRegex.test(input);
    }
    return false;
  };

  useEffect(() => {
    setIsFormSubmitted(false);
  }, [formData]);

  const [popupData, setPopupData] = useState<SharePopupData>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.getPopupData();
        setPopupData(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <form onSubmit={handleSubmit} className={className}>
        {popupData && (
          <React.Fragment>
            <div className={`${className}__item `}>
              <label className="text-large">{popupData.firstSubtitle}</label>
              <Choice
                className="choice"
                choices={popupData.choices}
                handleChoiceSelect={handleChoiceSelect}
              />
            </div>
            {choiceSelected && (
              <React.Fragment>
                <div className={`${className}__item info`}>
                  <label className="text-large">
                    {popupData.secondSubtitle}
                  </label>
                  {choiceSelected.isCountrySelect ? (
                    <TelInput
                      placeholder={choiceSelected.title}
                      value={formData.connectionItem}
                      error={isFormSubmitted && errors.connectionItem}
                      onChange={(v = "") =>
                        setFormData({ ...formData, connectionItem: v })
                      }
                    />
                  ) : (
                    <input
                      className={`${className}__input ${
                        isFormSubmitted && errors.connectionItem ? "error" : ""
                      }`}
                      type="text"
                      name="connectionItem"
                      value={formData.connectionItem}
                      placeholder={choiceSelected.title}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          connectionItem: e.target.value,
                        })
                      }
                    />
                  )}
                  <input
                    className={`${className}__input ${
                      isFormSubmitted && errors.nameUser ? "error" : ""
                    }`}
                    type="text"
                    name="nameUser"
                    value={formData.nameUser}
                    placeholder={popupData.placeholderName}
                    onChange={(e) =>
                      setFormData({ ...formData, nameUser: e.target.value })
                    }
                  />
                </div>
                <div className={`${className}__item`}>
                  <textarea
                    className={`${className}__textarea ${
                      isFormSubmitted && errors.message ? "error" : ""
                    }`}
                    name="message"
                    value={formData.message}
                    placeholder={popupData.placeholderMessage}
                    onChange={(e) =>
                      setFormData({ ...formData, message: e.target.value })
                    }
                  />
                </div>
                <div className={`${className}__item checkbox`}>
                  <input
                    id="checkbox"
                    className={`checkbox__input ${
                      isFormSubmitted && errors.privacyPolicy ? "error" : ""
                    }`}
                    type="checkbox"
                    name="privacyPolicy"
                    checked={formData.privacyPolicy}
                    onChange={() =>
                      setFormData({
                        ...formData,
                        privacyPolicy: !formData.privacyPolicy,
                      })
                    }
                  />
                  <label className={`checkbox__label `} htmlFor="checkbox">
                    <span className="checkbox__text text-normal">
                      {popupData.checkboxText}
                      {popupData.checkboxLink && (
                        <>
                          &nbsp;
                          <a
                            href={`${popupData.checkboxLink.path}`}
                            rel="noopener noreferrer"
                            target={
                              popupData.checkboxLink.isExternal ? "_blank" : ""
                            }
                            className="address__link"
                          >
                            {popupData.checkboxLink.text}
                          </a>
                        </>
                      )}
                    </span>
                  </label>
                </div>
                <Button type="submit" className="form__button">
                  {popupData.button.text}
                </Button>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </form>
      <div className="capchatext">
        <p className="text-micro">Protected by reCAPTCHA</p>
        <a
          className="text-micro"
          href="https://policies.google.com/privacy?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy
        </a>
        <a
          className="text-micro"
          target="_blank"
          rel="noopener noreferrer"
          href="https://policies.google.com/terms?hl=en"
        >
          Terms
        </a>
      </div>
    </>
  );
};

export default PopUp;
